import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Way Up',
    useCase: 'INTERIOR DESIGN, BRAND DEVELOPMENT, PACKAGING DESIGN',
    description: `Way Up is a new cannabis dispensary located in Warren Michigan. For their branding approach we wanted to create a style that would reflect a streetwear brand. Clean, simple, black and white. Most cannabis brands are bright and loud. We wanted to design a space that made customers not feel like they were in a dispensary. We designed the floor plans, preliminary architectual sketches and concept renderings to show how the space could look. We designed the exterior signage, interior artwork and the namesake branding packaging.

    I am very proud of this project and below is a sample of all the work we executed.`,
    link: 'wayupmichigan.com',
  },
  caseId: 'way-up',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
